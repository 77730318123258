import { Component, Input, OnInit } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { Facility, Installation } from 'projects/customerportal/src/app/interfaces/facilty';
import { AppModel, MixitSystemState, SystemType } from '@ams/dumbledore';
import { filter, first, map } from 'rxjs/operators';
import { AppError, AppErrorService, PageInfo } from 'shared';
import { PageInfoService } from 'projects/customerportal/src/app/services/page-info.service';
import { AlarmOverview, Application } from 'projects/customerportal/src/app/interfaces/alarm';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataPointsResult } from '../../interfaces/data-points';
import { getDatapoint, isConnect } from 'projects/customerportal/src/app/utils/mixit-utils';
import { SchematicsService } from '../../services/schematics.service';
import { DataPointsService } from '../../services/data-points.service';
import { MixingLoopControlType, MixitDataPointName, SetpointSource } from '../../interfaces/mixit';
import { AlarmService } from '../../services/alarm.service';
import { SystemDeviceType } from '../../interfaces/systemDeviceType';
import {
  IMixitSystemFormInterface
} from '../../../../../dumbledore/src/models/systems/mixitSystem/mixitSystemFormInterface';

@Component({
  selector: 'app-mixit-installation-page',
  templateUrl: './mixit-installation-page.component.html',
  styleUrls: ['./mixit-installation-page.component.scss']
})
export class MixitInstallationPageComponent implements OnInit {
  @Input() public facility$: Observable<Facility>;
  @Input() public installation$: Observable<Installation>;
  @Input() public schematic$: Observable<AppModel>;

  public application$: Observable<Application>;
  public pageInfo$: Observable<PageInfo>;
  public pageError$: Observable<AppError>;
  public dataPoints$: Observable<DataPointsResult>;
  public alarm$: Observable<AlarmOverview>;

  public premium$: Observable<boolean>;

  public isMixitCooling$: Observable<boolean>;
  public isDirectValveControl$: Observable<boolean>;

  constructor(
    private appErrorService: AppErrorService,
    private pageInfoService: PageInfoService,
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    private dataPointsService: DataPointsService,
    private schematicService: SchematicsService,
    private alarmService: AlarmService
  ) {
  }

  ngOnInit(): void {
    this.dataPoints$ = combineLatest([this.installation$, this.dataPointsService.dataPoints$]).pipe(
      filter(([_, datapoints]) => !!datapoints),
      map(([installation, dataPoints]) => dataPoints.find((d) => d.installationId === installation.id)),

      // This is to replace the setpoint value with local setpoint, if the source is local
      map((dataPoints) => {
        const system = dataPoints?.data.find((s) => s.systemType === SystemType.Mixit);
        if (!system) {
          return dataPoints as DataPointsResult;
        }

        // We have a separate temperature sensor that we use in Dumbledore, it's this value we need to modify
        const temperatureSensorUsedInDumbledore = system.devices.find((device) => {
          const isTemperatureSensor = device.type === SystemDeviceType.MixitSystem;
          const hasSetpointDataPoints = device.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.LocalSetpoint);
          return isTemperatureSensor && hasSetpointDataPoints;
        });

        if (!temperatureSensorUsedInDumbledore) {
          return dataPoints as DataPointsResult;
        }

        const mixitDevice = system.devices.find((d) => d.type === SystemDeviceType.MixitSystem);
        if (!mixitDevice) {
          return dataPoints as DataPointsResult;
        }

        const localSetPoint = mixitDevice.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.LocalSetpoint);
        const setPointSource = mixitDevice.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.SetpointSource);

        if (!localSetPoint || !setPointSource) {
          return dataPoints as DataPointsResult;
        }

        const dumbledoreSetpoint = mixitDevice.dataPoints.find((d) => d.humanReadableId === MixitDataPointName.LocalSetpoint);
        // If the source is local, we overwrite the values of the setpoint with the value of the local setpoint
        if (dumbledoreSetpoint && setPointSource.value === SetpointSource.MixingLoopSetpointSourceLocal) {
          dumbledoreSetpoint.value = localSetPoint.value;
          dumbledoreSetpoint.timestampEpoch = localSetPoint.timestampEpoch;
          dumbledoreSetpoint.unitType = localSetPoint.unitType;
        }

        return dataPoints as DataPointsResult;
      })
    );

    this.application$ = this.schematic$.pipe(
      map((schematic) => {
        const system = schematic.systems.find((s) => s.type === SystemType.Mixit) as MixitSystemState;
        const type = system.type;
        const result: Application = {
          title: system.systemInfo.title ? of(system.systemInfo.title) : this.translateService.get(`application-title.${type}`),
          id: system.hasId,
          type
        };
        return result;
      })
    );
    this.pageError$ = this.appErrorService.createPageErrorObservable([this.facility$, this.installation$, this.schematic$]);

    this.pageInfo$ = combineLatest([
      this.facility$.pipe(first()),
      this.installation$.pipe(first()),
      this.schematicService.getFirmwareVersion()
    ]).pipe(
      map(([facility, installation, firmwareVersion]) => {
        const pageInfo = this.pageInfoService.installation(facility, installation, firmwareVersion);
        return this.pageInfoService.fixInstallationPageForMixit(facility, installation, pageInfo);
      })
    );

    this.premium$ = isConnect(this.schematic$);

    this.alarm$ = this.alarmService.mostImportantAlarm$ as Observable<AlarmOverview>;

    this.isMixitCooling$ = this.schematicService?.schematicAndMeta$.pipe(
      map((schematicWithMeta) => {
        return (
          (schematicWithMeta.schematicDto.systems[0].systemInfo as IMixitSystemFormInterface).ThermalApplicationType !== 'MIXING_LOOP_THERMAL_APPLICATION_TYPE_HEAT_APPL'
        );
      })
    );

    // Check for 'Direct Valve Control' through event datapoint.
    this.isDirectValveControl$ = getDatapoint(
      this.dataPoints$,
      SystemDeviceType.MixitSystem,
      MixitDataPointName.MixingLoopControlSelection).pipe(
      map((data) => data?.value === MixingLoopControlType.DirectValveControl || false)
    );
  }

  editInstallation() {
    this.router.navigate(['/edit-installation', this.route.snapshot.params.facilityId, this.route.snapshot.params.installationId]);
  }

  editFacility() {
    this.router.navigate(['/edit-facility', this.route.snapshot.params.facilityId]);
  }
}
